.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.video-player-container {
	position: relative;
	width: 100%;
	height: 100%;
}

.video-player {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.video-player-overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}

.video-player-play {
	width: 50px;
	height: 50px;
	transition: transform 0.2s ease-in-out;
}

.video-player-overlay:hover {
	background-color: rgba(0, 0, 0, 0.45);
}

.video-player-overlay:hover .video-player-play {
	transform: scale(1.2);
}
